<template>
  <div
    style="overflow-x: hidden; overflow-y: scroll"
    :style="props.viewer ? 'height: 590px' : 'height: 490px'"
  >
    <ACard
      hoverable
      :bordered="false"
      style="width: 100%; text-align: left; margin-bottom: 20px"
    >
      <div v-if="props.draft" class="contentDraft">
        <span class="spanDraft">DRAFT</span>
      </div>
      <div id="headerLogo">
        <img
          id="imgFirm"
          v-if="user.tenant"
          :src="
            'https://s3-us-west-2.amazonaws.com/prima-pictures/' +
              user.tenant.logoUrl
          "
        />
        <div id="firm" class="clearfix">
          <div>{{ user.lawFirmName }}</div>
          <div>
            {{ user.address }}, <br />
            {{ user.state }} {{ user.zipCode }},
            {{ user.country }}
          </div>
          <div>{{ user.phone }}</div>
        </div>
      </div>
      <header class="clearfix">
        <div id="project">
          <div><span>CLIENT</span> {{ summary.contact.fullName }}</div>
          <div>
            <span>CASE</span> {{ summary.case.name }} -
            {{
              summary.case.caseType
                ? summary.case.caseType.name
                : summary.case.caseTypeName
            }}
          </div>
          <div>
            <span>ADDRESS</span> {{ summary.contact.pobCity }},
            {{ summary.contact.pobState }}, {{ summary.contact.pobCountry }}
          </div>
          <div>
            <span>EMAIL</span>
            <a :href="'mailto:' + summary.contact.email">{{
              summary.contact.email
            }}</a>
          </div>
          <div>
            <span>DATE</span> {{ moment(summary.payOn).format('MM/DD/YYYY') }}
          </div>
        </div>
      </header>
      <main id="main">
        <div style="margin-bottom: 10px; width: 100%">
          <span v-if="summary.terms || summary.termsAndConditions">
            <p>Terms and Conditions:</p>
            {{
              summary.termsAndConditions &&
              summary.termsAndConditions !== null &&
              summary.termsAndConditions !== ''
                ? summary.termsAndConditions
                : user.termsAndConditions &&
                  user.termsAndConditions !== null &&
                  user.termsAndConditions.length > 1
                ? user.termsAndConditions
                : 'Missing Terms & Conditions'
            }}
          </span>

          <h5 style="margin-top: 10px">
            {{ summary.description }}
          </h5>
        </div>

        <table class="center">
          <thead>
            <tr>
              <th class="service" style="text-align: right">INVOICE</th>
              <th class="desc" style="text-align: right">DUE DATE</th>
              <th style="text-align: right">TOTAL ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(invoice, invoiceIndex) in summary.invoices"
              :key="invoiceIndex"
            >
              <td class="total">
                {{ invoice.invoiceNumber ? invoice.invoiceNumber : invoice.ID }}
              </td>
              <td class="total">
                {{ moment(invoice.dueDate).format('MM/DD/YYYY') }}
              </td>
              <td class="total">
                {{ invoice.amount ? invoice.amount : invoice.invoiceTotal }}
              </td>
            </tr>
            <tr>
              <td colspan="2" class="grand total">Total Payment</td>
              <td class="grand total">{{ summary.totalPayment }}</td>
            </tr>
          </tbody>
        </table>

        <div style="margin-top: 100px; margin-bottom: 60px; width: 100%">
          <h5 style="margin-bottom: 40px">
            I have read the above document and agree to be bound to the terms as
            written above. If I am a non-native english speaker, this was
            explanined to me in my native language.
          </h5>

          Signed:___________________________ Date:________________
          <h5 style="margin-left: 50px">{{ summary.contact.fullName }}</h5>
        </div>

        <div
          v-if="
            (typeof summary.payingParty === 'string' &&
              summary.payingParty !== summary.contact.fullName) ||
              (typeof summary.payingParty === 'object' &&
                summary.payingParty.fullName !== summary.contact.fullName)
          "
          style="margin-bottom: 100px; width: 100%"
        >
          <h5 style="margin-bottom: 40px">
            I agree to be jointly and severably liable for the debt on this
            promissory note. I understand that if the other party does not pay,
            I will be legally required to pay the entire amount. If I am a
            non-native english speaker, this was explained to me in my native
            language.
          </h5>

          Signed:___________________________ Date:________________
          <h5 style="margin-left: 50px">
            {{
              typeof summary.payingParty === 'string'
                ? summary.payingParty
                : summary.payingParty.fullName
            }}
          </h5>
        </div>
      </main>
    </ACard>
  </div>

  <slot :download="download" />
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, watchEffect, toRaw } from 'vue';
import { UserService } from '@/services';
import { useGlobalProps } from '@/composables';
import moment from 'moment';
import { jsPDF } from 'jspdf';

interface Summary {
  contact: object;
  payingParty: object;
  case: object;
  totalPayment: number;
  payments: number;
  percentage: boolean;
  initialPayment: number;
  payOn: string;
  paymentPeriod: string;
  description: string;
  invoices: object;
  terms: boolean;
  pages: any;
  termsAndConditions?: string;
}

export default defineComponent({
  props: {
    draft: Boolean,
    viewer: Boolean,
  },
  setup(props) {
    /** Set user global */
    const { setUser, user, form, tenantPictureBase64 }: any = useGlobalProps();

    const summary = reactive<Summary>({
      contact: {},
      payingParty: {},
      case: {},
      totalPayment: 0,
      payments: 0,
      percentage: false,
      initialPayment: 0,
      payOn: '',
      paymentPeriod: '',
      description: '',
      invoices: [],
      terms: false,
      pages: [],
    });

    watchEffect(() => {
      if (form.value.invoices) {
        summary.contact = form.value.contact;
        summary.payingParty = form.value.payingParty
          ? form.value.payingParty
          : 'test pay';
        summary.case = form.value.case;
        summary.totalPayment = form.value.totalPayment;
        summary.payments = form.value.payments;
        summary.percentage = form.value.initialPayment
          ? form.value.initialPayment.modo === '%'
          : false;
        summary.initialPayment = form.value.initialPayment
          ? form.value.initialPayment.value
          : 0;
        summary.payOn = form.value.initialPayment
          ? form.value.initialPayment.payOn
          : moment().format('MM/DD/YYYY');
        summary.paymentPeriod = form.value.paymentPeriod;
        summary.description = form.value.description;
        summary.invoices = form.value.invoices;
        summary.terms = form.value.terms;
        summary.pages = [];
        summary.termsAndConditions = form.value.termsAndConditions;
      }
    });

    const getUser = async () => {
      try {
        // Get user
        const data = await UserService.get();
        setUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      if (props.draft) {
        getUser();
      }
    });

    /** Download PDF */
    const download = async () => {
      try {
        // jsPDF
        const doc = new jsPDF('p', 'pt', 'a4');

        // Set logo header

        // Add image
        const elImgFirm: any = document.getElementById('imgFirm');
        let widthImage;
        let heightImage;
        if (elImgFirm !== null) {
          widthImage = elImgFirm.offsetWidth;
          heightImage = elImgFirm.offsetHeight;

          try {
            doc.addImage(
              tenantPictureBase64.value,
              'PNG',
              40,
              30,
              Number(heightImage),
              Number(widthImage),
            );
          } catch (error) {
            console.log(error);
          }
        }

        // Set font size
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');

        let positionY = Number(heightImage);

        // Set firm information
        doc.text(user.value.lawFirmName, 400, Number(heightImage) - 45, {
          align: 'left',
        });
        doc.text(user.value.address, 400, Number(heightImage) - 30, {
          align: 'left',
        });
        doc.text(
          user.value.state +
            ' ' +
            user.value.zipCode +
            ' ' +
            user.value.country,
          400,
          Number(heightImage) - 15,
          { align: 'left' },
        );
        doc.text(user.value.phone, 400, Number(heightImage), {
          align: 'left',
        });

        // Set header

        doc.setFont('helvetica', 'bold');
        positionY += 60;

        // Set client information
        doc.text('CLIENT', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        const contact: any = summary.contact;

        doc.text(contact.fullName, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set case information
        doc.text('CASE', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        let caseFullName;
        const caseData: any = summary.case;

        if (caseData.caseType)
          caseFullName = caseData.name + ' - ' + caseData.caseType.name;
        else caseFullName = caseData.name + ' - ' + caseData.caseTypeName;

        doc.text(caseFullName, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set address
        doc.text('ADDRESS', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        let address = '';

        if (contact.pobCity && contact.pobCity !== '')
          address = contact.pobCity;
        else address = 'None';

        if (contact.pobState && contact.pobState !== '')
          address += ', ' + contact.pobState;
        else address += ', None';

        if (contact.pobCountry && contact.pobCountry !== '')
          address += ', ' + contact.pobCountry;
        else address += ', None';

        doc.text(address, 95, positionY, { align: 'left' });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set email
        doc.text('EMAIL', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        doc.text(contact.email, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set date
        doc.text('DATE', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        doc.text(moment(summary.payOn).format('MM/DD/YYYY'), 95, positionY, {
          align: 'left',
        });

        positionY += 40;

        // Set terms & conditions
        if (summary.terms) {
          doc.setFont('helvetica', 'bold');
          doc.text('Terms and Conditions:', 35, positionY);
          positionY += 15;
          doc.setFont('helvetica', 'normal');

          const termsAndConditions =
            summary.termsAndConditions &&
            summary.termsAndConditions !== null &&
            summary.termsAndConditions !== ''
              ? summary.termsAndConditions
              : user.value.termsAndConditions &&
                user.value.termsAndConditions !== null &&
                user.value.termsAndConditions.length > 1
              ? user.value.termsAndConditions
              : 'Missing Terms & Conditions';

          doc.text(termsAndConditions, 35, positionY, {
            maxWidth: 500,
          });

          positionY += Math.ceil(termsAndConditions.length / 90) * 10 + 20;
        }

        doc.setFont('helvetica', 'normal');

        doc.text(summary.description, 40, positionY, { maxWidth: 500 });

        positionY += Math.ceil(summary.description.length / 90) * 10 + 20;

        // Set info invoices for pages

        doc.setFont('helvetica', 'bold');

        doc.text('Invoice', 175, positionY, { align: 'right' });

        doc.text('Due Date', 300, positionY, { align: 'right' });

        doc.text('Amount ($)', 430, positionY, { align: 'right' });

        const invoices: any = summary.invoices;
        let contPages = 1;

        // Verify space on page and add page
        const addPageVerifySpace = () => {
          if (positionY > 700) {
            doc.addPage();

            positionY = 80;

            contPages++;

            return true;
          } else return false;
        };

        for (
          let contInvoice = 0;
          contInvoice < invoices.length;
          contInvoice++
        ) {
          if (contInvoice % 2 === 0) {
            doc.setDrawColor(0);
            doc.setFillColor(245, 245, 245);
            doc.rect(135, positionY + 10, 300, 25, 'F');
          }

          positionY += 25;

          const invoice = invoices[contInvoice];

          if (invoice.ID) invoice.invoiceNumber = invoice.ID;
          if (invoice.invoiceTotal) invoice.amount = invoice.invoiceTotal;

          doc.setFont('helvetica', 'normal');

          doc.text(String(invoice.invoiceNumber), 175, positionY, {
            align: 'right',
          });

          doc.text(
            moment(invoice.dueDate).format('MM/DD/YYYY'),
            300,
            positionY,
            { align: 'right' },
          );

          doc.text(String(invoice.amount), 430, positionY, {
            align: 'right',
          });

          addPageVerifySpace();
        }

        // Total Payment
        if (!addPageVerifySpace()) positionY += 25;

        doc.setDrawColor(0);
        doc.setFillColor(245, 245, 215);
        doc.rect(135, positionY - 15, 300, 25, 'F');

        doc.setFont('helvetica', 'bold');

        doc.text('Total Payment', 380, positionY, { align: 'right' });

        doc.setFont('helvetica', 'normal');

        doc.text(String(summary.totalPayment), 430, positionY, {
          align: 'right',
        });

        if (!addPageVerifySpace()) positionY += 50;

        doc.text(
          'I have read the above document and agree to be bound to the terms as written above. If I am a non-native english speaker, this was explained to me in my native language.',
          40,
          positionY,
          { maxWidth: 500 },
        );

        positionY += 50;

        // Set signeds
        doc.text('Signed: ', 40, positionY);
        doc.line(80, positionY, 250, positionY);

        // Set date
        doc.text('Date: ', 270, positionY);
        doc.line(300, positionY, 390, positionY);

        positionY += 10;

        doc.text(contact.fullName, 80, positionY);

        // Condition paying party

        const payingParty: any = summary.payingParty;

        if (
          (typeof payingParty === 'string' &&
            payingParty !== contact.fullName) ||
          (typeof summary.payingParty === 'object' &&
            payingParty.fullName !== contact.fullName)
        ) {
          if (!addPageVerifySpace()) positionY += 50;

          doc.text(
            'I agree to be jointly and severably liable for the debt on this promissory note. I understand that if the other party does not pay, I will be legally required to pay the entire amount. If I am a non-native english speaker, this was explained to me in my native language.',
            40,
            positionY,
            { maxWidth: 500 },
          );

          positionY += 60;

          doc.text('Signed: ', 40, positionY);
          doc.line(80, positionY, 250, positionY);

          // Set date
          doc.text('Date: ', 270, positionY);
          doc.line(300, positionY, 390, positionY);

          const payingPartyInfo = payingParty.fullName
            ? payingParty.fullName
            : contact.fullName;

          positionY += 10;

          doc.text(payingPartyInfo, 80, positionY);
        }

        // Write footer on all pages
        for (let contPage = 0; contPage < contPages; contPage++) {
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');
          doc.setTextColor(50, 50, 50);

          doc.setPage(contPage + 1);
          doc.text('Page ' + (contPage + 1) + ' of ' + contPages, 300, 810, {
            align: 'center',
          });

          // Set watermarker
          if (props.draft) {
            doc.setFontSize(100);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(255, 178, 178);
            doc.text('DRAFT', 180, 500, { angle: 40 });
          }
        }

        doc.save(
          user.value.lawFirmName +
            '-payment-plan-' +
            moment().format('MM-DD-YYYY') +
            '.pdf',
        );

        return Promise.resolve({ ...toRaw({ message: 'PDF Downloaded' }) });
      } catch (error) {
        return Promise.reject(error);
      }
    };

    return {
      user,
      summary,
      moment,
      props,
      download,
    };
  },
});
</script>

<style lang="scss" scoped>
.contentDraft {
  z-index: 99999;
  transform: rotate(-56deg);
  position: absolute;
  top: 50%;
  display: block;
  left: 50%;
  transform: translate(-50%, -50%);
}

.spanDraft {
  font-size: 120px;
  font-weight: bold;
  color: #bf1e2e;
  opacity: 0.1;
  transform: rotate(-45deg);
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin: -100px -180px;
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}

a {
  color: #5d6975;
  text-decoration: underline;
}

body {
  position: relative;
  width: 21cm;
  height: 29.7cm;
  margin: 0 auto;
  color: #001028;
  background: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-family: Arial;
}

header {
  padding: 10px 0;
  margin-bottom: 10px;
  width: 100%;
}

#headerLogo {
  position: relative;
  text-align: left;
  margin-bottom: 10px;
}

#headerLogo img {
  width: 180px;
  border-radius: 10px;
}

h1 {
  border-top: 1px solid #5d6975;
  border-bottom: 1px solid #5d6975;
  color: #5d6975;
  font-size: 1.4em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: center;
  margin: 0 0 20px 0;
  background: url(../assets/images/primalaw/dimension.png);
}

#project {
  float: left;
}

#project span {
  color: #5d6975;
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

#firm {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: left;
}

#project div,
#firm div {
  white-space: nowrap;
}

table {
  width: 80%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

table tr:nth-child(2n-1) td {
  background: #f5f5f5;
}

table th,
table td {
  text-align: center;
  font-size: 0.8em;
}

table th {
  padding: 5px 10px;
  color: #5d6975;
  border-bottom: 1px solid #c1ced9;
  white-space: nowrap;
  font-weight: normal;
}

table .service,
table .desc {
  text-align: left;
}

table td {
  padding: 10px;
  text-align: right;
}

table td.service,
table td.desc {
  vertical-align: top;
}

table td.unit,
table td.qty,
table td.total {
  font-size: 0.8em;
}

table td.grand {
  border-top: 1px solid #5d6975;
}

#notices .notice {
  color: #5d6975;
  font-size: 1.2em;
}

footer {
  color: #5d6975;
  width: 100%;
  height: 30px;
  // position: absolute;
  bottom: 0;
  border-top: 1px solid #c1ced9;
  padding: 8px 0;
  text-align: center;
}
</style>
