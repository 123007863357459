
import { defineComponent, onMounted, reactive, watchEffect, toRaw } from 'vue';
import { UserService } from '@/services';
import { useGlobalProps } from '@/composables';
import moment from 'moment';
import { jsPDF } from 'jspdf';

interface Summary {
  contact: object;
  payingParty: object;
  case: object;
  totalPayment: number;
  payments: number;
  percentage: boolean;
  initialPayment: number;
  payOn: string;
  paymentPeriod: string;
  description: string;
  invoices: object;
  terms: boolean;
  pages: any;
  termsAndConditions?: string;
}

export default defineComponent({
  props: {
    draft: Boolean,
    viewer: Boolean,
  },
  setup(props) {
    /** Set user global */
    const { setUser, user, form, tenantPictureBase64 }: any = useGlobalProps();

    const summary = reactive<Summary>({
      contact: {},
      payingParty: {},
      case: {},
      totalPayment: 0,
      payments: 0,
      percentage: false,
      initialPayment: 0,
      payOn: '',
      paymentPeriod: '',
      description: '',
      invoices: [],
      terms: false,
      pages: [],
    });

    watchEffect(() => {
      if (form.value.invoices) {
        summary.contact = form.value.contact;
        summary.payingParty = form.value.payingParty
          ? form.value.payingParty
          : 'test pay';
        summary.case = form.value.case;
        summary.totalPayment = form.value.totalPayment;
        summary.payments = form.value.payments;
        summary.percentage = form.value.initialPayment
          ? form.value.initialPayment.modo === '%'
          : false;
        summary.initialPayment = form.value.initialPayment
          ? form.value.initialPayment.value
          : 0;
        summary.payOn = form.value.initialPayment
          ? form.value.initialPayment.payOn
          : moment().format('MM/DD/YYYY');
        summary.paymentPeriod = form.value.paymentPeriod;
        summary.description = form.value.description;
        summary.invoices = form.value.invoices;
        summary.terms = form.value.terms;
        summary.pages = [];
        summary.termsAndConditions = form.value.termsAndConditions;
      }
    });

    const getUser = async () => {
      try {
        // Get user
        const data = await UserService.get();
        setUser(data);
      } catch (error) {
        console.error(error);
      }
    };

    onMounted(() => {
      if (props.draft) {
        getUser();
      }
    });

    /** Download PDF */
    const download = async () => {
      try {
        // jsPDF
        const doc = new jsPDF('p', 'pt', 'a4');

        // Set logo header

        // Add image
        const elImgFirm: any = document.getElementById('imgFirm');
        let widthImage;
        let heightImage;
        if (elImgFirm !== null) {
          widthImage = elImgFirm.offsetWidth;
          heightImage = elImgFirm.offsetHeight;

          try {
            doc.addImage(
              tenantPictureBase64.value,
              'PNG',
              40,
              30,
              Number(heightImage),
              Number(widthImage),
            );
          } catch (error) {
            console.log(error);
          }
        }

        // Set font size
        doc.setFontSize(10);
        doc.setFont('helvetica', 'normal');

        let positionY = Number(heightImage);

        // Set firm information
        doc.text(user.value.lawFirmName, 400, Number(heightImage) - 45, {
          align: 'left',
        });
        doc.text(user.value.address, 400, Number(heightImage) - 30, {
          align: 'left',
        });
        doc.text(
          user.value.state +
            ' ' +
            user.value.zipCode +
            ' ' +
            user.value.country,
          400,
          Number(heightImage) - 15,
          { align: 'left' },
        );
        doc.text(user.value.phone, 400, Number(heightImage), {
          align: 'left',
        });

        // Set header

        doc.setFont('helvetica', 'bold');
        positionY += 60;

        // Set client information
        doc.text('CLIENT', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        const contact: any = summary.contact;

        doc.text(contact.fullName, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set case information
        doc.text('CASE', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        let caseFullName;
        const caseData: any = summary.case;

        if (caseData.caseType)
          caseFullName = caseData.name + ' - ' + caseData.caseType.name;
        else caseFullName = caseData.name + ' - ' + caseData.caseTypeName;

        doc.text(caseFullName, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set address
        doc.text('ADDRESS', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        let address = '';

        if (contact.pobCity && contact.pobCity !== '')
          address = contact.pobCity;
        else address = 'None';

        if (contact.pobState && contact.pobState !== '')
          address += ', ' + contact.pobState;
        else address += ', None';

        if (contact.pobCountry && contact.pobCountry !== '')
          address += ', ' + contact.pobCountry;
        else address += ', None';

        doc.text(address, 95, positionY, { align: 'left' });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set email
        doc.text('EMAIL', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        doc.text(contact.email, 95, positionY, {
          align: 'left',
        });

        doc.setFont('helvetica', 'bold');
        positionY += 15;

        // Set date
        doc.text('DATE', 35, positionY, { align: 'left' });

        doc.setFont('helvetica', 'normal');

        doc.text(moment(summary.payOn).format('MM/DD/YYYY'), 95, positionY, {
          align: 'left',
        });

        positionY += 40;

        // Set terms & conditions
        if (summary.terms) {
          doc.setFont('helvetica', 'bold');
          doc.text('Terms and Conditions:', 35, positionY);
          positionY += 15;
          doc.setFont('helvetica', 'normal');

          const termsAndConditions =
            summary.termsAndConditions &&
            summary.termsAndConditions !== null &&
            summary.termsAndConditions !== ''
              ? summary.termsAndConditions
              : user.value.termsAndConditions &&
                user.value.termsAndConditions !== null &&
                user.value.termsAndConditions.length > 1
              ? user.value.termsAndConditions
              : 'Missing Terms & Conditions';

          doc.text(termsAndConditions, 35, positionY, {
            maxWidth: 500,
          });

          positionY += Math.ceil(termsAndConditions.length / 90) * 10 + 20;
        }

        doc.setFont('helvetica', 'normal');

        doc.text(summary.description, 40, positionY, { maxWidth: 500 });

        positionY += Math.ceil(summary.description.length / 90) * 10 + 20;

        // Set info invoices for pages

        doc.setFont('helvetica', 'bold');

        doc.text('Invoice', 175, positionY, { align: 'right' });

        doc.text('Due Date', 300, positionY, { align: 'right' });

        doc.text('Amount ($)', 430, positionY, { align: 'right' });

        const invoices: any = summary.invoices;
        let contPages = 1;

        // Verify space on page and add page
        const addPageVerifySpace = () => {
          if (positionY > 700) {
            doc.addPage();

            positionY = 80;

            contPages++;

            return true;
          } else return false;
        };

        for (
          let contInvoice = 0;
          contInvoice < invoices.length;
          contInvoice++
        ) {
          if (contInvoice % 2 === 0) {
            doc.setDrawColor(0);
            doc.setFillColor(245, 245, 245);
            doc.rect(135, positionY + 10, 300, 25, 'F');
          }

          positionY += 25;

          const invoice = invoices[contInvoice];

          if (invoice.ID) invoice.invoiceNumber = invoice.ID;
          if (invoice.invoiceTotal) invoice.amount = invoice.invoiceTotal;

          doc.setFont('helvetica', 'normal');

          doc.text(String(invoice.invoiceNumber), 175, positionY, {
            align: 'right',
          });

          doc.text(
            moment(invoice.dueDate).format('MM/DD/YYYY'),
            300,
            positionY,
            { align: 'right' },
          );

          doc.text(String(invoice.amount), 430, positionY, {
            align: 'right',
          });

          addPageVerifySpace();
        }

        // Total Payment
        if (!addPageVerifySpace()) positionY += 25;

        doc.setDrawColor(0);
        doc.setFillColor(245, 245, 215);
        doc.rect(135, positionY - 15, 300, 25, 'F');

        doc.setFont('helvetica', 'bold');

        doc.text('Total Payment', 380, positionY, { align: 'right' });

        doc.setFont('helvetica', 'normal');

        doc.text(String(summary.totalPayment), 430, positionY, {
          align: 'right',
        });

        if (!addPageVerifySpace()) positionY += 50;

        doc.text(
          'I have read the above document and agree to be bound to the terms as written above. If I am a non-native english speaker, this was explained to me in my native language.',
          40,
          positionY,
          { maxWidth: 500 },
        );

        positionY += 50;

        // Set signeds
        doc.text('Signed: ', 40, positionY);
        doc.line(80, positionY, 250, positionY);

        // Set date
        doc.text('Date: ', 270, positionY);
        doc.line(300, positionY, 390, positionY);

        positionY += 10;

        doc.text(contact.fullName, 80, positionY);

        // Condition paying party

        const payingParty: any = summary.payingParty;

        if (
          (typeof payingParty === 'string' &&
            payingParty !== contact.fullName) ||
          (typeof summary.payingParty === 'object' &&
            payingParty.fullName !== contact.fullName)
        ) {
          if (!addPageVerifySpace()) positionY += 50;

          doc.text(
            'I agree to be jointly and severably liable for the debt on this promissory note. I understand that if the other party does not pay, I will be legally required to pay the entire amount. If I am a non-native english speaker, this was explained to me in my native language.',
            40,
            positionY,
            { maxWidth: 500 },
          );

          positionY += 60;

          doc.text('Signed: ', 40, positionY);
          doc.line(80, positionY, 250, positionY);

          // Set date
          doc.text('Date: ', 270, positionY);
          doc.line(300, positionY, 390, positionY);

          const payingPartyInfo = payingParty.fullName
            ? payingParty.fullName
            : contact.fullName;

          positionY += 10;

          doc.text(payingPartyInfo, 80, positionY);
        }

        // Write footer on all pages
        for (let contPage = 0; contPage < contPages; contPage++) {
          doc.setFontSize(10);
          doc.setFont('helvetica', 'normal');
          doc.setTextColor(50, 50, 50);

          doc.setPage(contPage + 1);
          doc.text('Page ' + (contPage + 1) + ' of ' + contPages, 300, 810, {
            align: 'center',
          });

          // Set watermarker
          if (props.draft) {
            doc.setFontSize(100);
            doc.setFont('helvetica', 'bold');
            doc.setTextColor(255, 178, 178);
            doc.text('DRAFT', 180, 500, { angle: 40 });
          }
        }

        doc.save(
          user.value.lawFirmName +
            '-payment-plan-' +
            moment().format('MM-DD-YYYY') +
            '.pdf',
        );

        return Promise.resolve({ ...toRaw({ message: 'PDF Downloaded' }) });
      } catch (error) {
        return Promise.reject(error);
      }
    };

    return {
      user,
      summary,
      moment,
      props,
      download,
    };
  },
});
